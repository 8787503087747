import React, { useState } from 'react'
import navStyles from "./nav-items.module.scss"


const NavItems = () => {
    return (
        <nav>
            <ul className={navStyles.navbar}>
                <li>
                    <div className={navStyles.dropdown}>
                        <div className={navStyles.dropdownLink}>
                            <a href="https://shhkids.org/about-us/#">ABOUT US</a>
                        </div>
                        <ul className={navStyles.dropdownContent}>
                            <li><a href="https://shhkids.org/about-us/">Our Story</a></li>
                            <li><a href="https://shhkids.org/about-us/our-people/">Our People</a></li>
                            <li><a href="https://shhkids.org/about-us/our-values/">Our Values</a></li>
                            <li><a href="https://shhkids.org/blog/">Our Blog</a></li>
                            <li><a href="https://shhkids.org/about-us/why-honduras/">Why Honduras</a></li>
                        </ul>

                    </div>

                </li>
                <li className={navStyles.dropdown}>
                    <div className={navStyles.dropdownLink}>
                        <a href="https://shhkids.org/volunteer/">VOLUNTEER</a>

                    </div>
                    <ul className={navStyles.dropdownContent}>
                        <li><a href="https://shhkids.org/trips/">Register</a></li>
                        <li><a href="https://shhkids.org/volunteer/#whychooseus">Why Choose Us?</a></li>
                        <li><a href="https://shhkids.org/volunteer/#cost">Cost</a></li>
                        <li><a href="https://shhkids.org/volunteer/#financialassistance">Financial Assistance</a></li>
                        <li><a href="https://shhkids.org/volunteer/#safetyandsecurity">Safety and Security</a></li>
                        <li><a href="https://shhkids.org/volunteer/#accomodations">Accomodations</a></li>
                        <li><a href="https://shhkids.org/volunteer/#sampleitinerary">Itinerary</a></li>
                        <li><a href="https://shhkids.org/volunteer/#groupvolunteering">Group Volunteering</a></li>
                        <li><a href="https://shhkids.org/volunteer/#youngprofessionals">Youngs Professionals</a></li>
                        <li><a href="https://shhkids.org/volunteer/#downtimeactivities">Downtime Activities</a></li>
                        <li><a href="https://shhkids.org/volunteer/#meetourstaff">Meet Our Staff</a></li>
                        <li><a href="https://shhkids.org/volunteer/#letterfromthefounder">Letter from The Founder</a></li>
                        <li><a href="https://shhkids.org/volunteer/#preparationandpackinglist">Preparation and Packing List</a></li>
                        <li><a href="https://shhkids.org/volunteer/#health">Health</a></li>
                        <li><a href="https://shhkids.org/volunteer/#airportpickuppolicy">Airport Pickup Policy</a></li>
                        <li><a href="https://shhkids.org/volunteer/#cancellationpolicy">Cancellation Policies</a></li>
                        <li><a href="https://shhkids.org/volunteer/#faqs">FAQs</a></li>
                    </ul>
                </li>

                <li className={navStyles.dropdown}>
                    <div className={navStyles.dropdownLink}>
                        <a href="https://shhkids.org/takeaction/">TAKE ACTION</a>

                    </div>
                    <ul className={navStyles.dropdownContent}>
                        <li><a href="https://shhkids.org/fundraise/">Fundraise</a></li>
                        <li><a href="https://shhkids.org/takeaction/chapters/">Chapters</a></li>
                        <li><a href="https://shhkids.org/takeaction/leadership/">Leadership Week</a></li>
                        <li><a href="https://shhkids.org/takeaction/teach-english/">Teach English</a></li>
                        <li><a href="https://shhkids.org/takeaction/teach-summer-camp/">Tech Summer Camp</a></li>
                        <li><a href="https://shhkids.org/takeaction/jobsandinternships/">Jobs and Internships</a></li>
                    </ul>
                </li>

                <li className={navStyles.dropdown}>
                    <div className={navStyles.dropdownLink}>
                        <a href="https://shhkids.org/projects/">PROJECTS</a>

                    </div>
                    <ul className={navStyles.dropdownContent}>
                        <li><a href="https://shhkids.org/projects/school-builds/">School Builds</a></li>
                        <li><a href="https://shhkids.org/projects/villa-soleada/">Villa Soleada</a></li>
                        <li><a href="https://shhkids.org/projects/childrens-home/">Villa Soleada Children's Home</a></li>
                        <li><a href="https://shhkids.org/projects/villa-soleada-bilingual-school/">Villa Soleada Bilingual School</a></li>
                        <li><a href="http://trainforchange.org/">Train for Change</a></li>
                    </ul>
                </li>

                <li className={navStyles.dropdown}>
                    <div className={navStyles.dropdownLink}>
                        <a href="http://www.onethousandschools.com/">YOUNG PROFESSIONALS</a>

                    </div>
                </li>

                <li className={navStyles.dropdown}>
                    <div className={navStyles.dropdownLink}>
                        <a href="https://shhkids.org/donate/">DONATE</a>

                    </div>
                    <ul className={navStyles.dropdownContent}>
                        <li><a href="https://my.shhkids.org/give/145887/#!/donation/checkout">One Time</a></li>
                        <li><a href="https://shhkids.org/tigersclub/">Tigers Club</a></li>
                        <li><a href="https://my.shhkids.org/campaign/one-cup-of-coffee/c145895">Give Monthly</a></li>
                        <li><a href="https://shhkids.org/fundraise/">Fundraise</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    )
}

export default NavItems