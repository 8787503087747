import React from 'react'
import NavItems from "./nav-items"
import Sidebar from "./sidebar"
import Logo from "../../images/shh-logo.png"
import Hamburger from "../../images/hamburger_icon.svg"
import shhHeaderStyles from "./shh-header.module.scss"

const ShhHeader = ({ toggle, setToggle }) => {

    return (
        <header className={shhHeaderStyles.header}>

            <div className={shhHeaderStyles.topbar}>
                <div className={shhHeaderStyles.topbarContent}>
                    <div className={shhHeaderStyles.coronaInfo}>
                        <p>
                            Call/Text : +1 631 505 3744 |
                            Email: info@shkids.org |
                            -----
                                <a href="https://shhkids.org/covid19">READ THIS IMPORTANT MESSAGE REGARDING COVID19</a>
                                -----
                            </p>
                    </div>

                    <div className={shhHeaderStyles.topbarLinks}>
                        <ul>
                            <li className={shhHeaderStyles.borderRight}>
                                <p>
                                    <a href="https://shhkids.org/trips/">Trip Dates</a>
                                </p>
                            </li>
                            <li className={shhHeaderStyles.borderRight}>
                                <p>
                                    <a href="https://shhkids.org/contact/">Contact Us</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://my.shhkids.org/login">Login</a>
                                </p>
                            </li>
                        </ul>
                    </div>


                </div>

            </div>

            <div className={shhHeaderStyles.headerContainer}>
                <img className={shhHeaderStyles.logo} src={Logo} alt="Logo" />
                <NavItems />
                <img
                    onClick={() => setToggle(!toggle)}
                    className={shhHeaderStyles.hamburger}
                    src={Hamburger}
                    alt="Menu button"
                />
            </div>

        </header>
    )
}

export default ShhHeader