import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF, faYoutube, faVimeo, faInstagram } from '@fortawesome/free-brands-svg-icons'

import shhFooterStyles from "./shh-footer.module.scss"

const ShhFooter = () => {
    return (
        <div className={shhFooterStyles.footer}>
            <div className={shhFooterStyles.footerSection}>

                <div className={shhFooterStyles.textContainer}>
                    <p className={shhFooterStyles.heading}>GET TO KNOW US</p>

                    <ul>
                        <li><a href="https://shhkids.org/about-us/">our story</a></li>
                        <li><a href="https://shhkids.org/about-us/our-people/">our team</a></li>
                        <li><a href="https://shhkids.org/projects/">our projects</a></li>
                    </ul>
                </div>

                <div className={shhFooterStyles.textContainer}>
                    <p className={shhFooterStyles.heading}>GET INVOLVED</p>

                    <ul>
                        <li><a href="https://shhkids.org/volunteer/">volunteer</a></li>
                        <li><a href="https://shhkids.org/donate/">donate</a></li>
                        <li><a href="https://shhkids.org/takeaction/chapters/">chapters</a></li>
                    </ul>
                </div>

                <div className={shhFooterStyles.textContainer}>
                    <p className={shhFooterStyles.heading}>GET SOCIAL</p>

                    <ul className={shhFooterStyles.social}>
                        <li className={`${shhFooterStyles.icon} ${shhFooterStyles.twitter}`}>
                            <a href="http://www.twitter.com/SHHonduras">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </li>
                        <li className={`${shhFooterStyles.icon} ${shhFooterStyles.facebook}`}>
                            <a href="https://www.facebook.com/studentshelpinghonduras?fref=ts">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </li>
                        <li className={`${shhFooterStyles.icon} ${shhFooterStyles.youtube}`}>
                            <a href="https://www.youtube.com/user/SHHonduras">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                        </li>
                        <li className={`${shhFooterStyles.icon} ${shhFooterStyles.vimeo}`}>
                            <a href="http://www.vimeo.com/user6071489">
                                <FontAwesomeIcon icon={faVimeo} />
                            </a>
                        </li>
                        <li className={`${shhFooterStyles.icon} ${shhFooterStyles.instagram}`}>
                            <a href="http://instagram.com/SHHonduras">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                    </ul>
                </div>

                <div className={shhFooterStyles.textContainer}>
                    <p className={shhFooterStyles.heading}>GET IN TOUCH</p>

                    <p>
                        71 Pine View Drive<br />
                        Fredericksburg, VA 22406<br />
                        (631) 505-3744<br />
                        M-F 10am-5pm<br />
                        info@sshkids.org<br />
                    </p>
                </div>

            </div>

            <div className={shhFooterStyles.copyrightSection}>
                <p>
                    students helping honduras is a 501(c)(3) Tax ID: 41-2191361 |
                    all contributions are tax deductible |
                    all rights reserved (c) 2015 |
                    created by <span>Alperen Kan</span>
                </p>
            </div>
        </div>
    )
}

export default ShhFooter
