import React, { useState } from "react"
import Header from "./shh/shh-header"
import Footer from "./shh/shh-footer"
import Sidebar from "./shh/sidebar"
import { logout } from "../utils/auth"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'


import "../styles/index.scss"
import layoutStyles from "./layout.module.scss"

const Layout = ({ children }) => {

    const [toggle, setToggle] = useState(false)


    return (
        <div className={layoutStyles.wrapper}>
            <Sidebar setToggle={setToggle} />

            <div id={layoutStyles.main} className={`${layoutStyles.main} ${toggle ? layoutStyles.open : ""}`}>

                <Header toggle={toggle} setToggle={setToggle} />
                <div className={layoutStyles.logoutContainer}>
                    <button className={layoutStyles.logoutButton} onClick={() => logout()}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                    </button>
                </div>

                <div className={layoutStyles.content}>
                    {children}
                </div>

                <Footer />

            </div>
        </div>
    )
}

export default Layout
