import React, { useState } from 'react'
import { navigate } from "gatsby"
import sidebarStyles from "./sidebar.module.scss"


const Sidebar = ({ setToggle }) => {

    const [value, setValue] = useState("")

    const submitSearch = (e) => {
        e.preventDefault()
        navigate(`https://shhkids.org/?s=${value}`)
    }

    return (
        <div className={sidebarStyles.sidebarContainer}>
            <div className={sidebarStyles.searchbarContainer}>
                <form className={sidebarStyles.searchbar} onSubmit={(e) => submitSearch(e)}>
                    <input value={value} type="text" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                </form>

                <button className={sidebarStyles.closeButton} onClick={() => setToggle()}>X</button>


            </div>
            <nav>
                <ul className={sidebarStyles.mainlist}>
                    <li className={sidebarStyles.sublist}>
                        <h1 className={sidebarStyles.sublistHeading}>
                            <a href="https://shhkids.org/about-us/#">ABOUT US</a>
                        </h1>
                        <ul className={sidebarStyles.subitemList}>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/about-us/">Our Story</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/about-us/our-people/">Our People</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/about-us/our-values/">Our Values</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/blog/">Our Blog</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/about-us/why-honduras/">Why Honduras</a>
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li className={sidebarStyles.sublist}>
                        <h1 className={sidebarStyles.sublistHeading}>
                            <a href="https://shhkids.org/volunteer/">VOLUNTEER</a>

                        </h1>
                        <ul className={sidebarStyles.subitemList}>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/trips/">Register</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#whychooseus">Why Choose Us?</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#cost">Cost</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#financialassistance">Financial Assistance</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#safetyandsecurity">Safety and Security</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#accomodations">Accomodations</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#sampleitinerary">Itinerary</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#groupvolunteering">Group Volunteering</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#youngprofessionals">Youngs Professionals</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#downtimeactivities">Downtime Activities</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#meetourstaff">Meet Our Staff</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#letterfromthefounder">Letter from The Founder</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#preparationandpackinglist">Preparation and Packing List</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#health">Health</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#airportpickuppolicy">Airport Pickup Policy</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#cancellationpolicy">Cancellation Policies</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/volunteer/#faqs">FAQs</a>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li className={sidebarStyles.sublist}>
                        <h1 className={sidebarStyles.sublistHeading}>
                            <a href="https://shhkids.org/takeaction/">TAKE ACTION</a>

                        </h1>
                        <ul className={sidebarStyles.subitemList}>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/fundraise/">Fundraise</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/takeaction/chapters/">Chapters</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/takeaction/leadership/">Leadership Week</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/takeaction/teach-english/">Teach English</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/takeaction/teach-summer-camp/">Tech Summer Camp</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/takeaction/jobsandinternships/">Jobs and Internships</a>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li className={sidebarStyles.sublist}>
                        <h1 className={sidebarStyles.sublistHeading}>
                            <a href="https://shhkids.org/projects/">PROJECTS</a>

                        </h1>
                        <ul className={sidebarStyles.subitemList}>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/projects/school-builds/">School Builds</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/projects/villa-soleada/">Villa Soleada</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/projects/childrens-home/">Villa Soleada Children's Home</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/projects/villa-soleada-bilingual-school/">Villa Soleada Bilingual School</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="http://trainforchange.org/">Train for Change</a>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li className={sidebarStyles.sublist}>
                        <h1 className={sidebarStyles.sublistHeading}>
                            <a href="http://www.onethousandschools.com/">YOUNG PROFESSIONALS</a>

                        </h1>
                    </li>

                    <li className={sidebarStyles.sublist}>
                        <h1 className={sidebarStyles.sublistHeading}>
                            <a href="https://shhkids.org/donate/">DONATE</a>

                        </h1>
                        <ul className={sidebarStyles.subitemList}>
                            <li>
                                <p>
                                    <a href="https://my.shhkids.org/give/145887/#!/donation/checkout">One Time</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/tigersclub/">Tigers Club</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://my.shhkids.org/campaign/one-cup-of-coffee/c145895">Give Monthly</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a href="https://shhkids.org/fundraise/">Fundraise</a>
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div >
    )
}

export default Sidebar